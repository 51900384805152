// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-7f872034] {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  position: fixed;\n  overflow-y: auto;\n}\n.container .title[data-v-7f872034] {\n    text-align: center;\n    color: #929DA3;\n    margin: 20px 0;\n    font-size: 14px;\n}\n.container .box[data-v-7f872034] {\n    padding: 15px;\n    margin: 0 15px 15px 15px;\n    background: #FFFFFF;\n    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);\n}\n.container .img-list[data-v-7f872034] {\n    display: flex;\n    flex-wrap: wrap;\n    align-content: flex-start;\n    margin-bottom: 15px;\n}\n.container .img-title[data-v-7f872034] {\n    margin-bottom: 15px;\n}\n.container .img-listbox[data-v-7f872034] {\n    margin-right: 13px;\n}\n.container .img-listbox img[data-v-7f872034] {\n      width: 52px;\n      height: 64px;\n}\n", ""]);
// Exports
module.exports = exports;
