var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("goback", { attrs: { msg: _vm.msg } }),
      _c("div", { staticClass: "title" }, [_vm._v("以下是您已上传的图像")]),
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.list, function(item) {
          return _c("div", [
            _c("div", { staticClass: "img-title" }, [
              _vm._v(
                _vm._s(_vm.getImgName(item.informationType)) +
                  " " +
                  _vm._s(item.fileList.length) +
                  "页"
              )
            ]),
            _c(
              "div",
              { staticClass: "img-list" },
              _vm._l(item.fileList, function(ite) {
                return _c(
                  "div",
                  {
                    staticClass: "img-listbox",
                    on: {
                      click: function($event) {
                        return _vm.imgShow(ite.currentNumber)
                      }
                    }
                  },
                  [_c("img", { attrs: { src: ite.url } })]
                )
              }),
              0
            )
          ])
        }),
        0
      ),
      _c("van-image-preview", {
        attrs: { images: _vm.images, startPosition: _vm.index - 1 },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function() {
              return [_vm._v("第" + _vm._s(_vm.index) + "页")]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.show,
          callback: function($$v) {
            _vm.show = $$v
          },
          expression: "show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }