<template>
  <div class="container">
    <goback :msg='msg'></goback>
    <div class="title">以下是您已上传的图像</div>
    <div class="box">
      <div v-for="item in list">
        <div class="img-title">{{getImgName(item.informationType)}} {{item.fileList.length}}页</div>
        <div class="img-list">
          <div class="img-listbox" v-for="ite in item.fileList" @click="imgShow(ite.currentNumber)">
            <img :src="ite.url" />
          </div>
        </div>
      </div>
    </div>
    <van-image-preview v-model="show" :images="images"  :startPosition="index-1" @change="onChange">
      <template v-slot:index>第{{ index }}页</template>
    </van-image-preview>
  </div>
</template>

<script>
 import { categoryList,enumList} from '@/api'
import Vue from 'vue'
import goback from '../goBack/index.vue'
import { ImagePreview} from 'vant';
Vue.use(ImagePreview);
export default {
  name: 'caseList',
  data() {
    return {
      msg:"我的预核保",
      list:[],
      initList:{},
      show:false,
      index:0,
      images:[]
    }
  },
   components: {
     goback
   },
  created() {
    this.getInit();
    this.getList()
  },
  methods: {
    //获取枚举信息
    getInit(){
      enumList().then((res) => {
        let obj = {};
        res.map((e) => {
          obj[e.enumType] = e.enumList;
        });
        this.initList = obj
      })
    },
    getList() {
      categoryList(this.$route.query.caseId).then((res) => {
        this.list = res
        for(let y=0; y < res.length; y++){
            for(let i = 0; i < res[y].fileList.length; i++){
              this.images.push(res[y].fileList[i].artworkMasterUrl)
            }
        }

      })
    },
    getImgName(val){
       return this.initList.informationType.filter(item => item.code===val )[0].name
    },
    imgShow(index){
      this.show = true
      this.index = index
    },
    onChange(index) {
      this.index = index+1;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y:auto;
  .title{
    text-align:center;
    color: #929DA3;
    margin: 20px 0;
    font-size: 14px;
  }
  .box{
    padding: 15px;
    margin: 0 15px 15px 15px;
    background: #FFFFFF;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
  }
  .img-list{
    display: flex;
    flex-wrap:wrap;
    align-content:flex-start; 
    margin-bottom: 15px;
  }
  .img-title{
    margin-bottom: 15px;
  }
  .img-listbox{
    margin-right: 13px;
    img{
      width: 52px;
      height: 64px;
    }
  }
}
</style>
